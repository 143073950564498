

.courier {
    display: flex;
    align-items: center;
}
.courier .info-text {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.courier-infoBox {
    display: flex;
    background-color: #67be23;
    align-items: center;
    padding: 10px 13px;
    margin-left: 20px;
    border-radius: 10px;
}

.courier-infoBox .mobile,
.delivery-img {
    color: #ffffff !important;
}

.courier-infoBox .text {
    display: flex;
    flex-direction: column;
}

.courier-box-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
}

.courier-box-container svg {
    margin-right: 10px;
}

.product {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    right: 0;
}

/* Container holding the image and the text */
.container {
    position: relative;
    text-align: center;
  }
  
  /* Bottom left text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    margin-left: 56%;
  }

.product-text {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    white-space: nowrap;
}

.product-footer {
    display: flex;
    justify-content: flex-end;
    font-weight: 800;
}

.product-footer > span:first-child {
    margin-right: 10px;
    color: #67be23;
}

.product-footer > span:nth-child(2) {
    margin-right: 20px;
}

.zoom {
    transition: transform .2s; /* Animation */
    margin: 0 auto;
}

.zoom:hover {
    transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.container-gowork-operators {
    display: flex;
    width: "100%";
    position: relative;
    top: 0;
    left: 0;
}

.container-gowork-operators-image {
    position: relative;
    top: 0;
    left: 0;
}

.avatar-url {
    position: relative;
    top: 0;
    left: 0;
}


.avatar-url-category {
    position: absolute;
    top: 150px;
    margin-left: 35%;
}




@media screen and (max-width: 1199px) {
    .courier-infoBox {
        margin-right: 12px;
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .courier-box-container {
        margin-top: 35px;
        flex-direction: column;
    }

    .courier-infoBox {
        margin-bottom: 15px;
        width: 100%;
    }

    .courier .info-text {
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 576px) {
    .pageHeader {
        padding: 16px 16px;
    }

    .pageHeader .ant-page-header-heading-title {
        font-size: 16px;
    }

    .courier-infoBox {
        margin-left: 0;
    }
    .courier {
        flex-direction: column;
    }

    .courier .info-text {
        text-align: center;
        margin-left: 0;
        margin-top: 10px;
    }
    .courier-box-container {
        margin-top: 20px;
    }
}
