.courier {
    display: flex;
    align-items: center;
}
.courier .info-text {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.courier-infoBox {
    display: flex;
    background-color: #67be23;
    align-items: center;
    padding: 10px 13px;
    margin-left: 20px;
    border-radius: 10px;
}

.courier-infoBox .mobile,
.delivery-img {
    color: #ffffff !important;
}

.courier-infoBox .text {
    display: flex;
    flex-direction: column;
}

.courier-box-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
}

.courier-box-container svg {
    margin-right: 10px;
}

.product {
    display: flex;
    align-items: center;
}

.product-text {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    white-space: nowrap;
}

.product-footer {
    display: flex;
    justify-content: flex-end;
    font-weight: 800;
}

.product-footer > span:first-child {
    margin-right: 10px;
    color: #67be23;
}

.product-footer > span:nth-child(2) {
    margin-right: 20px;
}

:root {
    --primary: #0676ed;
    --background: #222b45;
    --warning: #f2a600;
    --success: #12c99b;
    --error: #e41749;
    --dark: #151a30;
  }

.alert {
    min-height: 67px;
    width: 560px;
    max-width: 90%;
    border-radius: 12px;
    padding: 16px 22px 17px 20px;
    display: flex;
    align-items: center;

  }
  .alert-warning {
    background: var(--warning);
  }
  .alert-info {
    background: var(--primary);
  }
  .alert-news {
    background: var(--success);
  }
  .alert-event {
    background: var(--dark);
  }
  .alert-alarm {
    background: var(--error);
  }
  .alert .icon__wrapper {
    height: 48px;
    width: 48px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alert .icon__wrapper span {
    font-size: 21px;
    color: #fff;
  }
  .alert p {
    color: #fff;
    font-family: Verdana;
    margin-left: 10px;
  }
  .alert p a,
  .alert p a:visited,
  .alert p a:active {
    color: #fff;
  }
  .alert .open {
    margin-left: auto;
    margin-right: 5px;
  }
  .alert .close, .alert .open {
    color: #fff;
    transition: transform 0.5s;
    font-size: 18px;
    cursor: pointer;
  }
  .alert .close:hover, .alert .open:hover {
    transform: scale(1.3);
  }

@media screen and (max-width: 1199px) {
    .courier-infoBox {
        margin-right: 12px;
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .courier-box-container {
        margin-top: 35px;
        flex-direction: column;
    }

    .courier-infoBox {
        margin-bottom: 15px;
        width: 100%;
    }

    .courier .info-text {
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 576px) {
    .pageHeader {
        padding: 16px 16px;
    }

    .pageHeader .ant-page-header-heading-title {
        font-size: 16px;
    }

    .courier-infoBox {
        margin-left: 0;
    }
    .courier {
        flex-direction: column;
    }

    .courier .info-text {
        text-align: center;
        margin-left: 0;
        margin-top: 10px;
    }
    .courier-box-container {
        margin-top: 20px;
    }
}
